import {HandleInputs, handleInputs} from "../utils/handleInputs";
import {Button} from "./ui/button";
import * as React from "react";
import {
    Dialog, DialogClose,
    DialogContent,
    DialogFields,
    DialogFooter,
    DialogHeader,
    DialogHeaderIcon,
    DialogTitle,
    DialogTrigger
} from "./ui/dialog";
import {ArrowRightFromLine} from "lucide-react";
import { TurnkeyClient } from "@turnkey/http";
import { WebauthnStamper } from "@turnkey/webauthn-stamper";
import { IframeStamper, KeyFormat } from '@turnkey/iframe-stamper';

const stamper = new WebauthnStamper({
    rpId: window.location.hostname === 'localhost' ? 'localhost' : 'thunder.gg'
  });
  const turnkeyClient = new TurnkeyClient(
    {
      baseUrl: "https://api.turnkey.com"
    },
    stamper
  );

const DEFAULT_STATE = {
    open: false,
    destination: '',
    amount: '',

    transferLoading: false,
}

export function ModalExportWallet(props) {

    const {
        inputs,
        setInputs,
        updateInputs,
        errors,
        setErrors,
        clearErrors
    } = HandleInputs({
        initialState: DEFAULT_STATE,
    })

    React.useEffect(() => {
        if (inputs.open) {
            setTimeout(async () => {
                const iframeStamper = new IframeStamper({
                    iframeUrl: 'https://export.turnkey.com',
                    iframeContainer: document.getElementById("export-iframe"),
                    iframeElementId: "export-iframe"
                });
            
                const publicKey = await iframeStamper.init();
    
                const exportResult = await turnkeyClient.exportWallet({
                    type: 'ACTIVITY_TYPE_EXPORT_WALLET',
                    timestampMs: Date.now().toString(),
                    organizationId: props.organizationId,
                    parameters: {
                    walletId: props.walletId,
                    targetPublicKey: publicKey,
                    },
                })
    
                let success = await iframeStamper.injectWalletExportBundle(
                    exportResult.activity.result.exportWalletResult.exportBundle
                );
            }, 1000);
        }
    }, [inputs.open])

    return (
        <Dialog
            open={inputs.open}
            onOpenChange={open => setInputs((prev) => ({...prev, open}))}
        >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setInputs((prev) => ({...prev, open: true}))}
                    style={{
                        marginRight: 10
                    }}
                >
                    Export
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogHeaderIcon icon={ArrowRightFromLine} />
                    <DialogTitle>Export</DialogTitle>
                </DialogHeader>
                <DialogFields>
                <style>{`iframe {
      box-sizing: border-box;
      height: 120px;
      width: 100%;
      border-radius: 0.25rem;
                    }`}</style>
                    <div className="w-full" id="export-iframe"></div>
                </DialogFields>
                <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" disabled={inputs.transferLoading}>Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export function ModalExportPrivateKey(props) {

    const {
        inputs,
        setInputs,
        updateInputs,
        errors,
        setErrors,
        clearErrors
    } = HandleInputs({
        initialState: DEFAULT_STATE,
    })

    React.useEffect(() => {
        if (inputs.open) {
            setTimeout(async () => {
                const iframeStamper = new IframeStamper({
                    iframeUrl: 'https://export.turnkey.com',
                    iframeContainer: document.getElementById("export-iframe"),
                    iframeElementId: "export-iframe"
                });
            
                const publicKey = await iframeStamper.init();
    
                const exportResult = await turnkeyClient.exportPrivateKey({
                    type: 'ACTIVITY_TYPE_EXPORT_PRIVATE_KEY',
                    timestampMs: Date.now().toString(),
                    organizationId: props.organizationId,
                    parameters: {
                    privateKeyId: props.privateKeyId,
                    targetPublicKey: publicKey,
                    },
                })
    
                let success = await iframeStamper.injectKeyExportBundle(
                    exportResult.activity.result.exportPrivateKeyResult.exportBundle
                );
            }, 1000);
        }
    }, [inputs.open])

    return (
        <Dialog
            open={inputs.open}
            onOpenChange={open => setInputs((prev) => ({...prev, open}))}
        >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setInputs((prev) => ({...prev, open: true}))}
                    style={{
                        marginRight: 10
                    }}
                >
                    Export
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogHeaderIcon icon={ArrowRightFromLine} />
                    <DialogTitle>Export</DialogTitle>
                </DialogHeader>
                <DialogFields>
                    <style>{`iframe {
      box-sizing: border-box;
      height: 120px;
      width: 100%;
      border-radius: 0.25rem;
                    }`}</style>
                    <div className="w-full" id="export-iframe"></div>
                </DialogFields>
                <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" disabled={inputs.transferLoading}>Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}