import {useCallback, useMemo, useState} from "react";
import { TurnkeyClient } from "@turnkey/http";
import { WebauthnStamper } from "@turnkey/webauthn-stamper";
import { ethers } from "ethers";
import { TurnkeySigner } from "@turnkey/ethers";
import {Button} from "./components/ui/button";
import {DataTable} from "./components/ui/table/data-table";
import {Loader2} from "lucide-react";
import * as React from "react";
import {cn} from "./utils/utils";
import {ModalExportWallet, ModalExportPrivateKey} from "./components/modalExport";
import {Select} from "./components/ui/select";
import {Chain, getChainOptions} from "./config/Chain";
import {FormSelect} from "./components/ui/form/select";
import ERC20ABI from './utils/erc20abi'

const publicProviders = {
  [Chain.Mainnet]: 'https://cloudflare-eth.com',
  [Chain.Arbitrum]: 'https://arbitrum-one.publicnode.com',
  [Chain.Base]: 'https://base.publicnode.com',
  [Chain.Avax]: 'https://avalanche-c-chain.publicnode.com',
  [Chain.Polygon]: 'https://polygon-bor.publicnode.com',
  [Chain.Optimism]: 'https://optimism.publicnode.com',
  [Chain.Solana]: 'https://api.mainnet-beta.solana.com'
}

const stamper = new WebauthnStamper({
  rpId: window.location.hostname === 'localhost' ? 'localhost' : 'thunder.gg'
});
const turnkeyClient = new TurnkeyClient(
  {
    baseUrl: "https://api.turnkey.com"
  },
  stamper
);

function Spinner(props) {
  const {className} = props
  return <Loader2 className={cn("w-4 h-4 mr-2 text-secondary-foreground animate-spin duration-500", className)} />
}

function Login({
  loginToTurnkey, loading
}) {
  return (
      <div className="text-center space-y-3">
        <p
            className="text-secondary-foreground md:max-w-[500px]"
        >
          Welcome to Thunder Wallet Backup! This site is a fallback solution to access your wallets' funds. Login with your passkey to continue.        </p>
        <Button
            onClick={() => loginToTurnkey()}
            disabled={loading}
        >
          {loading ? (
              <div className="flex items-center">
                <Spinner />Logging you in
              </div>
          ) : "Login"}
        </Button>
      </div>
  )
}

function RetrieveWallets({
  retrieveWallets, loading
}) {
  return (
      <div className="text-center space-y-3">
        <p
            className="text-secondary-foreground md:max-w-[500px]"
        >
          You have successfully logged in! Use your passkey to retrieve your wallets.
        </p>
        <Button
            onClick={() => retrieveWallets()}
            disabled={loading}
        >
          {loading ? (
              <div className="flex items-center">
                <Spinner />Retrieving wallets
              </div>
          ) : "Retrieve wallets"}
        </Button>
      </div>
  )
}

function PrivateKeys({
  orgId,
  privateKeys,
  chain,
}) {
    const transferNative = useCallback(async (key, inputs) => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(publicProviders[chain])
        const turnkeyWallet = new TurnkeySigner({
          client: turnkeyClient,
          organizationId: orgId,
          signWith: key.privateKeyId,
        }, provider);
        turnkeyWallet.getAddress = () => key.addresses[0].address

        const txn = await turnkeyWallet.populateTransaction({
          value: ethers.utils.parseEther(inputs.amount),
          from: key.addresses[0].address,
          to: inputs.destination
        })
        const signedTxn = await turnkeyWallet.signTransaction(txn)

        const txnResp = await provider.sendTransaction(signedTxn)
        const txnReceipt = await provider.waitForTransaction(txnResp.hash)
        if (txnReceipt.status !== 1) {
          alert('Transaction Failed!')
          return
        }
        alert('Sucessfully transferred!')
      } catch(e) {
        alert(e.message || e)
      }
    }, [orgId, chain])

    const transferERC20 = useCallback(async (key, inputs) => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(publicProviders[chain])
        const turnkeyWallet = new TurnkeySigner({
          client: turnkeyClient,
          organizationId: orgId,
          signWith: key.privateKeyId,
        }, provider);
        turnkeyWallet.getAddress = () => key.addresses[0].address

        const ERC20 = new ethers.Contract(inputs.contractAddress, ERC20ABI, turnkeyWallet)

        const decimals = await ERC20.decimals()

        if (typeof decimals !== "number" || Number.isNaN(decimals)) {
          alert('Unable to fetch token decimals')
          return
        }

        const contractTxn = await ERC20.populateTransaction.transfer(inputs.destination, ethers.utils.parseUnits(inputs.amount, decimals))
        const txn = await turnkeyWallet.populateTransaction(contractTxn)
        const signedTxn = await turnkeyWallet.signTransaction(txn)

          const txnResp = await provider.sendTransaction(signedTxn)
          const txnReceipt = await provider.waitForTransaction(txnResp.hash)
          if (txnReceipt.status !== 1) {
            alert('Transaction Failed!')
            return
          }
          alert('Sucessfully transferred!')
      } catch(e) {
        alert(e.message || e)
      }
    }, [orgId, chain])

  const columns = useMemo(() => [
    {
      accessorKey: "privateKeyName",
      header: "Name"
    },
    {
      header: "Address",
      cell: ({ row }) => <span>{row.original.addresses[0].address}</span>
    },
    {
      header: "Actions",
      cell: ({ row }) => (
          <div>
              <ModalExportPrivateKey privateKeyId={row.original.privateKeyId} organizationId={orgId} />
          </div>
      )
    },
  ], [])

  return (
      <div className="">
        <DataTable
            columns={columns}
            data={privateKeys}
            loading={false}
        />
      </div>
  )
}

function Wallets({
  orgId,
  wallets,
  chain,
}) {

  const columns = useMemo(() => [
    {
      accessorKey: "walletName",
      header: "Name"
    },
    {
      header: "Actions",
      cell: ({ row }) => (
          <div>
              <ModalExportWallet walletId={row.original.walletId} organizationId={orgId} />
          </div>
      )
    },
  ], [])

  return (
      <div>
        <DataTable
            columns={columns}
            data={wallets}
            loading={false}
        />
      </div>
  )
}

function App() {
  const [userDetails, setUserDetails] = useState()
  const [privateKeys, setPrivateKeys] = useState([])
  const [solanaWallets, setSolanaWallets] = useState([])

  const [loginLoading, setLoginLoading] = useState(false)
  const [retrieveWalletsLoading, setRetrieveWalletsLoading] = useState(false)

  const [chain, setChain] = useState(Chain.Mainnet)

  const loginToTurnkey = useCallback(async () => {
    setLoginLoading(true)
    const whoamiInfo = await turnkeyClient.getWhoami({
      organizationId: 'ebb949e6-0ad7-4c88-bdb5-ecbd437d5107'
    })

    setUserDetails(whoamiInfo)
    setLoginLoading(false)
  }, [])

  const retrieveWallets = useCallback(async () => {
    setRetrieveWalletsLoading(true)
    const privateKeys = await turnkeyClient.getPrivateKeys({
      organizationId: userDetails.organizationId
    })

    setPrivateKeys(privateKeys.privateKeys)
    setRetrieveWalletsLoading(false)
  }, [userDetails])

  const retrieveSolanaWallets = useCallback(async () => {
    setRetrieveWalletsLoading(true)
    const solanaWallets = await turnkeyClient.getWallets({
      organizationId: userDetails.organizationId
    })

    setSolanaWallets(solanaWallets.wallets)
    setRetrieveWalletsLoading(false)
  }, [userDetails])

  const CHAIN_OPTIONS = getChainOptions()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
      <img
          src="/images/thunderBetaLogoFull.svg"
          className="mb-3"
      />
      <div className="px-4 md:px-0 w-full md:!w-fit md:max-w-container-max-width-desktop">
        {!userDetails && <Login loginToTurnkey={loginToTurnkey} loading={loginLoading} />}
        {(userDetails && <div className="flex items-center justify-between mb-3">
          <p
              className="text-secondary-foreground"
          >
            Wallet List
          </p>
          <Select
              label="Chain"
              options={CHAIN_OPTIONS}
              value={chain}
              onChange={(value) => setChain(value)}
          />
        </div>)}
        {(userDetails && chain === Chain.Solana && solanaWallets.length === 0) && <RetrieveWallets retrieveWallets={retrieveSolanaWallets} loading={retrieveWalletsLoading} />}
        {(userDetails && chain !== Chain.Solana && privateKeys.length === 0) && <RetrieveWallets retrieveWallets={retrieveWallets} loading={retrieveWalletsLoading} />}
        {(userDetails && chain === Chain.Solana && solanaWallets.length > 0) && <Wallets orgId={userDetails.organizationId} wallets={solanaWallets} chain={chain} />}
        {(userDetails && chain !== Chain.Solana && privateKeys.length > 0) && <PrivateKeys orgId={userDetails.organizationId} privateKeys={privateKeys} chain={chain} />}
      </div>
    </div>
  );
}

export default App;
